import { useQuery } from '@tanstack/react-query';

import { useRequestListagem } from 'hooks/useRequestListagem';

export type ComunicadoTags = {
    idComunicadoTagComunicado: number;
    idTagComunicado: number;
    dsTagComunicado: string;
};

export type Comunicado = {
    fgVisto: boolean;
    fgConfirmado: boolean;
    idComunicado: number;
    qtVisualizacao: number;
    qtCurtida: number;
    fgUsuarioCurtiu: boolean;
    fgSolicitaConfirmacao: boolean;
    fgExibePopup: boolean;
    fgAtivo: boolean;
    dsLinkCapa: string;
    dsConteudo: string;
    dsComunicado: string;
    dhInclusao: string;
    comunicadoTagsComunicado: ComunicadoTags[];
    categoriaComunicado: {
        idCategoriaComunicado: number;
        dsCategoriaComunicado: string;
        dsLinkCapa: string;
    };
};

export function useQueryComunicado() {
    const requestListagem = useRequestListagem();

    return useQuery<Comunicado[]>(
        ['queryComunicado'],
        () =>
            requestListagem({
                url: '/gestao/comunicado/popup',
            }).then((res) => res?.data?.data),
        {
            enabled: false,
        },
    );
}

import { ConfirmModal } from 'components/modal';
import { brlDate } from 'util/format';
import { usePopupContext } from 'context/PopupContext';

const isDateCurrentDate = (date: string | null) => {
    if (!date) {
        return false;
    }

    const currentDate = new Date();
    const dateToCompare = new Date(date);

    currentDate.setHours(0, 0, 0, 0);
    dateToCompare.setHours(0, 0, 0, 0);

    return currentDate.getTime() === dateToCompare.getTime();
};

export function GradeModal() {
    const { dataGrade, openGradeModal, setOpenGradeModal } = usePopupContext();
    const dtLimitePedido = dataGrade ? `${dataGrade?.dtLimitePedido}T00:00:00` : null;
    const dayText = isDateCurrentDate(dtLimitePedido) ? 'hoje' : 'amanhã';

    return (
        <ConfirmModal
            open={openGradeModal}
            setOpen={setOpenGradeModal}
            showCancel={false}
            title="Grade"
            confirmText="Fechar"
            text={
                <>
                    Sua data limite para digitação de <b>{dataGrade?.tipoGrade?.dsTipoGrade?.toUpperCase()}</b> vence {dayText}, dia{' '}
                    <b> {dtLimitePedido && brlDate(dtLimitePedido)}</b>
                </>
            }
        />
    );
}

import { ReactNode } from 'react';

import { Box, Typography } from '@mui/material';

import { useBreakpoints } from 'hooks/useBreakpoints';

type ComunicadoHeaderTitle = {
    title: string;
    content: ReactNode;
};

export function ComunicadoHeaderSection({ title, content }: ComunicadoHeaderTitle) {
    const { isDownMd } = useBreakpoints();

    return (
        <Box>
            <Typography variant={isDownMd ? 'body2' : undefined} sx={{ color: 'primary.main' }}>
                {title}
            </Typography>

            <Typography variant={isDownMd ? 'body1' : 'h6'} sx={{ color: 'common.black' }}>
                {content}
            </Typography>
        </Box>
    );
}

import { Divider } from '@mui/material';

import { ContentModal } from 'components/modal';
import { ComunicadoContent } from 'components/modal/comunicado/content/ComunicadoContent';
import { ComunicadoFooter } from 'components/modal/comunicado/footer/ComunicadoFooter';
import { ComunicadoHeader } from 'components/modal/comunicado/header/ComunicadoHeader';
import { ComunicadoModalProvider } from 'context/ComunicadoModalContext';
import { usePopupContext } from 'context/PopupContext';

export function ComunicadoModal() {
    const { openComunicadoModal, setOpenComunicadoModal } = usePopupContext();

    return (
        <ComunicadoModalProvider>
            <ContentModal
                open={openComunicadoModal}
                setOpen={setOpenComunicadoModal}
                disableEscapeKeyDown
                disableBackdropClick
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: { xs: '90dvw', md: '70dvw' },
                    maxHeight: 'auto',
                    p: 0,
                    borderRadius: 1,
                }}
            >
                <ComunicadoHeader />

                <Divider />

                <ComunicadoContent />

                <Divider />

                <ComunicadoFooter />
            </ContentModal>
        </ComunicadoModalProvider>
    );
}

import ChevronRight from '@mui/icons-material/ChevronRight';
import { Button, Tooltip } from '@mui/material';

import { useComunicadoModalContext } from 'context/ComunicadoModalContext';
import { useMutateVisualizar } from 'hooks/comunicado-modal/useMutateVisualizacao';

export function ComunicadoFooterNextBtn() {
    const { comunicado, comunicados, changeComunicado, setComunicados, indexComunicado } = useComunicadoModalContext();
    const { mutateVisualizar, isLoading } = useMutateVisualizar();
    const isLastComunicado = comunicados.length - 1 === indexComunicado;
    const hasToValidate = comunicado?.fgSolicitaConfirmacao && !comunicado?.fgConfirmado;
    const tooltipTitle = hasToValidate && !isLastComunicado ? 'É necessário clicar em "Li e Estou Ciente" para habilitar o botão' : '';
    const disabled = isLoading || hasToValidate || isLastComunicado;

    /**
     * Atualiza o comunicado ativo para marcar como visto.
     */
    const updateVistoComunicado = () =>
        setComunicados((oldComunicados) =>
            oldComunicados.map((oldComunicado) => {
                const isSameComunicado = oldComunicado.idComunicado === comunicado?.idComunicado;

                if (isSameComunicado) {
                    return {
                        ...oldComunicado,
                        fgVisto: true,
                    };
                }

                return oldComunicado;
            }),
        );

    /**
     * Ao fechar o modal, caso o comunicado não tenha sido lido,
     * realiza a request para informar que foi lido, atualiza a lista de comunicados
     * e avança ao próximo comunicado
     */
    const onClickButton = () => {
        if (!comunicado?.fgVisto) {
            mutateVisualizar();

            updateVistoComunicado();
        }

        changeComunicado('NEXT');
    };

    return (
        <Tooltip title={tooltipTitle}>
            <span>
                <Button variant="outlined" disabled={disabled} onClick={onClickButton} endIcon={<ChevronRight />} sx={{ width: '100%' }}>
                    Próximo
                </Button>
            </span>
        </Tooltip>
    );
}

import { useQuery } from '@tanstack/react-query';

import { useRequestListagem } from 'hooks/useRequestListagem';

export function useQueryDre() {
    const requestListagem = useRequestListagem();

    return useQuery<boolean>(
        ['queryDre'],
        () =>
            requestListagem({
                url: '/dre/dremercadologica/popup',
            }).then((res) => res?.data?.data),
        {
            enabled: false,
        },
    );
}

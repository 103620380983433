import ChevronLeft from '@mui/icons-material/ChevronLeft';
import { Button } from '@mui/material';

import { useComunicadoModalContext } from 'context/ComunicadoModalContext';

export function ComunicadoFooterPreviousBtn() {
    const { indexComunicado, changeComunicado } = useComunicadoModalContext();
    const isFirstComunicado = indexComunicado === 0;

    return (
        <Button variant="outlined" startIcon={<ChevronLeft />} onClick={() => changeComunicado('PREVIOUS')} disabled={isFirstComunicado}>
            Voltar
        </Button>
    );
}

import CloseIcon from '@mui/icons-material/Close';
import { Button, Tooltip } from '@mui/material';

import { useComunicadoModalContext } from 'context/ComunicadoModalContext';
import { usePopupContext } from 'context/PopupContext';
import { useMutateVisualizar } from 'hooks/comunicado-modal/useMutateVisualizacao';

export function ComunicadoFooterCloseBtn() {
    const { comunicado, comunicados, indexComunicado } = useComunicadoModalContext();
    const { setOpenComunicadoModal } = usePopupContext();
    const { mutateVisualizar, isLoading } = useMutateVisualizar();
    const isLastComunicado = comunicados.length - 1 === indexComunicado;
    const hasToValidate = comunicado?.fgSolicitaConfirmacao && !comunicado?.fgConfirmado;
    const tooltipText = isLastComunicado && hasToValidate ? 'É necessário clicar em "Li e Estou Ciente" para habilitar o botão' : '';
    const disabled = isLoading || !isLastComunicado || (comunicado?.fgSolicitaConfirmacao && !comunicado?.fgConfirmado);

    /**
     * Ao fechar o modal, caso o comunicado não tenha sido lido, realiza a request para atualizar a flag e fecha o modal.
     */
    const onClickButton = () => {
        if (!comunicado?.fgVisto) {
            mutateVisualizar();
        }

        setOpenComunicadoModal(false);
    };

    return (
        <Tooltip title={tooltipText}>
            <span style={{ gridColumn: comunicado?.fgSolicitaConfirmacao ? '' : 'span 2' }}>
                <Button variant="contained" disabled={disabled} onClick={onClickButton} sx={{ width: '100%' }} endIcon={<CloseIcon />}>
                    Fechar
                </Button>
            </span>
        </Tooltip>
    );
}

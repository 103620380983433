import { ConfirmModal } from 'components/modal';
import { usePopupContext } from 'context/PopupContext';

export function DreModal() {
    const { openDreModal, setOpenDreModal } = usePopupContext();

    return (
        <ConfirmModal
            open={openDreModal}
            setOpen={setOpenDreModal}
            showCancel={false}
            title="Preencher documentos de estimativa de resultado"
            confirmText="Fechar"
            text="Você possui documentos de estimativa de resultado pendente de finalização, favor acessar e finalizar."
        />
    );
}

import { useComunicadoModalContext } from 'context/ComunicadoModalContext';
import { useFormMutation } from 'mutations/useFormMutation';

export function useMutateVisualizar() {
    const { mutate, isLoading } = useFormMutation();
    const { comunicado } = useComunicadoModalContext();

    /**
     * Realiza a request de post para visualizar o comunicado.
     */
    const mutateVisualizar = () => {
        let url = `/gestao/comunicadovisualizacao/add?idComunicado=${comunicado?.idComunicado}`;

        if (comunicado?.fgSolicitaConfirmacao) {
            url = `/gestao/comunicadoleitura/add?idComunicado=${comunicado?.idComunicado}`;
        }

        mutate({
            url,
            preventSnack: true,
        });
    };

    return { mutateVisualizar, isLoading };
}

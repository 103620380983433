import { Box } from '@mui/material';

import { Imagem } from 'components/images';
import { Comunicado } from 'hooks/comunicado-modal/useQueryComunicado';

export function ComunicadoContentImage({ comunicado }: { comunicado: Comunicado | null }) {
    return (
        <>
            {comunicado?.dsLinkCapa && (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flex: 1,
                        height: '100%',
                        my: { xs: 1, md: 0 },
                    }}
                >
                    <Imagem src={comunicado.dsLinkCapa} style={{ maxHeight: '100%', borderRadius: 4 }} />
                </Box>
            )}
        </>
    );
}

import { useQuery } from '@tanstack/react-query';

import { useRequestListagem } from 'hooks/useRequestListagem';

export type Grade = {
    dtLimitePedido: string;
    tipoGrade: {
        dsTipoGrade: string;
    };
};

export function useQueryGrade() {
    const requestListagem = useRequestListagem();

    return useQuery<Grade>(
        ['queryGrade'],
        () =>
            requestListagem({
                url: '/gestao/grade/popup',
            }).then((res) => res?.data?.data),
        {
            enabled: false,
        },
    );
}

import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from 'react';

import { usePopupContext } from 'context/PopupContext';
import { Comunicado } from 'hooks/comunicado-modal/useQueryComunicado';

export type ComunicadoModalContext = {
    indexComunicado: number;
    changeComunicado(action: 'NEXT' | 'PREVIOUS'): void;

    comunicado: Comunicado | null;
    setComunicado: Dispatch<SetStateAction<Comunicado | null>>;

    comunicados: Comunicado[];
    setComunicados: Dispatch<SetStateAction<Comunicado[]>>;

    disabledCheckboxConfirm: boolean;
    setDisabledCheckboxConfirm: Dispatch<SetStateAction<boolean>>;
};

const ComunicadoModalContext = createContext<ComunicadoModalContext>({} as ComunicadoModalContext);

/**
 * Ordena os comunicados com solicitação de confirmação primeiro
 */
const sortComunicadados = (comunicados: Comunicado[]): Comunicado[] =>
    comunicados.sort((a, b) => Number(b.fgSolicitaConfirmacao) - Number(a.fgSolicitaConfirmacao));

/**
 * Insere o index do comunicado nele mesmo.
 */
const formatComunicados = (comunicados: Comunicado[]): Comunicado[] =>
    comunicados.map((comunicado) => ({
        ...comunicado,
        fgVisto: false,
        fgConfirmado: false,
    }));

export function ComunicadoModalProvider({ children }: { children: ReactNode }) {
    const { dataComunicado } = usePopupContext();

    const [comunicado, setComunicado] = useState<Comunicado | null>(null);
    const [comunicados, setComunicados] = useState<Comunicado[]>([]);
    const [disabledCheckboxConfirm, setDisabledCheckboxConfirm] = useState<boolean>(false);

    /**
     * Retorna o index do comunicado atual.
     */
    const indexComunicado = comunicados.findIndex((item) => item.idComunicado === comunicado?.idComunicado);

    /**
     * Altera o comunicado ativo de acordo com a ação.
     */
    const changeComunicado = (action: 'NEXT' | 'PREVIOUS') => {
        let index = indexComunicado;

        if (action === 'NEXT') {
            index += 1;
        } else if (action === 'PREVIOUS') {
            index -= 1;
        }

        const newComunicado = comunicados[index];

        if (newComunicado) {
            setComunicado(newComunicado);
        }
    };

    /**
     * Define os dados iniciais do comunicado.
     */
    const setInitialData = () => {
        if (Array.isArray(dataComunicado)) {
            const sortedComunicados = sortComunicadados(dataComunicado);
            const formattedComunicados = formatComunicados(sortedComunicados);

            setComunicados(formattedComunicados);

            setComunicado(formattedComunicados[0]);
        }
    };

    useEffect(setInitialData, [dataComunicado]);

    return (
        <ComunicadoModalContext.Provider
            value={{
                indexComunicado,
                changeComunicado,

                comunicado,
                setComunicado,

                comunicados,
                setComunicados,

                disabledCheckboxConfirm,
                setDisabledCheckboxConfirm,
            }}
        >
            {children}
        </ComunicadoModalContext.Provider>
    );
}

export const useComunicadoModalContext = () => useContext(ComunicadoModalContext);

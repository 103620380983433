import { useGetDomainConfig } from 'hooks/useGetDomainConfig';
import { BiPageComp } from 'pages/bi/components';
import { InicioUsuarioInfo } from 'pages/inicio/components';

export function InicioDetailComp() {
    const { tipoPedido } = useGetDomainConfig();
    const isTipoPedidoFuncionario = tipoPedido === 'funcionario';
    const isTipoPedidoPadrao = tipoPedido === 'padrao';

    return (
        <>
            {isTipoPedidoFuncionario && <InicioUsuarioInfo />}

            {isTipoPedidoPadrao && <BiPageComp />}
        </>
    );
}

import { Box } from '@mui/material';
import * as dateFns from 'date-fns';

import { ComunicadoHeaderSection } from 'components/modal/comunicado/header/ComunicadoHeaderSection';
import { Tag, TagGroup } from 'components/text';
import { useComunicadoModalContext } from 'context/ComunicadoModalContext';

export function ComunicadoHeader() {
    const { comunicado } = useComunicadoModalContext();

    return (
        <Box
            sx={{
                display: { xs: 'grid', md: 'flex' },
                gridTemplateColumns: '1fr 1fr',
                px: 2,
                py: 1.5,
                gap: { xs: 1, md: 2 },
            }}
        >
            {comunicado?.dsComunicado && <ComunicadoHeaderSection title="Título" content={comunicado.dsComunicado} />}

            {comunicado?.categoriaComunicado?.dsCategoriaComunicado && (
                <ComunicadoHeaderSection title="Categoria" content={comunicado.categoriaComunicado.dsCategoriaComunicado} />
            )}

            {Array.isArray(comunicado?.comunicadoTagsComunicado) && Boolean(comunicado?.comunicadoTagsComunicado.length) && (
                <ComunicadoHeaderSection
                    title="Hashtags"
                    content={
                        <TagGroup max={3}>
                            {comunicado?.comunicadoTagsComunicado.map((comunicadoTag) => (
                                <Tag label={comunicadoTag.dsTagComunicado} key={comunicadoTag.idTagComunicado} />
                            ))}
                        </TagGroup>
                    }
                />
            )}

            {comunicado?.dhInclusao && (
                <ComunicadoHeaderSection title="Publicado" content={dateFns.format(new Date(comunicado.dhInclusao), 'dd/MM/yyyy')} />
            )}
        </Box>
    );
}

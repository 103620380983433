import { SyntheticEvent } from 'react';

import { Checkbox, FormControlLabel, Tooltip } from '@mui/material';

import { useComunicadoModalContext } from 'context/ComunicadoModalContext';

export function ComunicadoFooterCheckbox() {
    const { comunicado, setComunicado, setComunicados, disabledCheckboxConfirm } = useComunicadoModalContext();
    const hasToValidate = comunicado?.fgSolicitaConfirmacao && !comunicado?.fgConfirmado;
    const tooltipTitle = hasToValidate && disabledCheckboxConfirm ? 'É necessário ler todo o comunicado para habilitar a flag' : '';
    const disabled = !comunicado?.fgSolicitaConfirmacao || comunicado?.fgVisto || disabledCheckboxConfirm;

    /**
     * Muda o valor do fgConfirmado do comunicado ativo e da lista de comunicados.
     */
    const onChangeCheckbox = (e: SyntheticEvent, checked: boolean) => {
        setComunicado((oldComunicado) => oldComunicado && { ...oldComunicado, fgConfirmado: checked });

        setComunicados((oldComunicados) =>
            oldComunicados.map((oldComunicado) => {
                const isSameComunicado = oldComunicado.idComunicado === comunicado?.idComunicado;

                if (isSameComunicado) {
                    return {
                        ...oldComunicado,
                        fgConfirmado: checked,
                    };
                }

                return oldComunicado;
            }),
        );
    };

    return (
        <Tooltip title={tooltipTitle}>
            <span>
                <FormControlLabel
                    label="Li e estou ciente"
                    sx={{ m: 0 }}
                    control={<Checkbox />}
                    checked={comunicado?.fgConfirmado}
                    disabled={disabled}
                    onChange={onChangeCheckbox}
                />
            </span>
        </Tooltip>
    );
}

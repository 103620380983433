import { Typography, Box } from '@mui/material';

import { ComunicadoFooterCheckbox } from 'components/modal/comunicado/footer/ComunicadoFooterCheckbox';
import { ComunicadoFooterCloseBtn } from 'components/modal/comunicado/footer/ComunicadoFooterCloseBtn';
import { ComunicadoFooterNextBtn } from 'components/modal/comunicado/footer/ComunicadoFooterNextBtn';
import { ComunicadoFooterPreviousBtn } from 'components/modal/comunicado/footer/ComunicadoFooterPreviousBtn';
import { useComunicadoModalContext } from 'context/ComunicadoModalContext';
import { useBreakpoints } from 'hooks/useBreakpoints';

export function ComunicadoFooter() {
    const { comunicado, comunicados, indexComunicado } = useComunicadoModalContext();
    const { isDownMd } = useBreakpoints();

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                px: 2,
                py: 1.5,
            }}
        >
            {!isDownMd && (
                <Typography>
                    <strong>{indexComunicado + 1}</strong> de <strong>{comunicados.length}</strong> comunicado(s)
                </Typography>
            )}

            <Box
                sx={{
                    display: { xs: 'grid', md: 'flex' },
                    flex: { xs: 1, md: 'none' },
                    gridTemplateColumns: '1fr 1fr',
                    gap: { xs: 1, md: 2 },
                    alignItems: 'center',
                }}
            >
                {!isDownMd && comunicado?.fgSolicitaConfirmacao && <ComunicadoFooterCheckbox />}

                <ComunicadoFooterPreviousBtn />

                <ComunicadoFooterNextBtn />

                {isDownMd && comunicado?.fgSolicitaConfirmacao && <ComunicadoFooterCheckbox />}

                <ComunicadoFooterCloseBtn />
            </Box>
        </Box>
    );
}

import { UIEvent, useEffect, useRef } from 'react';

import { Box } from '@mui/material';

import { RichContent } from 'components/content';
import { ComunicadoContentImage } from 'components/modal/comunicado/content/ComunicadoContentImage';
import { useComunicadoModalContext } from 'context/ComunicadoModalContext';
import { useBreakpoints } from 'hooks/useBreakpoints';

export function ComunicadoContent() {
    const { comunicado, setDisabledCheckboxConfirm, disabledCheckboxConfirm } = useComunicadoModalContext();
    const conteudoRef = useRef<HTMLDivElement>(null);
    const { isDownMd } = useBreakpoints();
    const idComunicado = comunicado?.idComunicado as number;

    /**
     * Ao trocar de comunicado, caso o conteúdo do modal seja maior que o tamanho do componente, desabilita o checkbox de confirmação.
     * Caso contrário, habilita o checkbox.
     */
    const handleDisabledCheckboxConfirm = () => {
        if (conteudoRef.current) {
            conteudoRef.current.scrollTop = 0;

            const hasScroll = conteudoRef.current.scrollHeight > conteudoRef.current.clientHeight;

            setDisabledCheckboxConfirm(hasScroll);
        } else {
            setDisabledCheckboxConfirm(false);
        }
    };

    /**
     * Ao chegar no fim da scrollagem do conteúdo, habilita o checkbox de confirmação.
     */
    const onScrollContent = (e: UIEvent<HTMLDivElement>) => {
        const isOnBottom = e.currentTarget.scrollHeight - Math.ceil(e.currentTarget.scrollTop) <= e.currentTarget.clientHeight + 64;

        if (isOnBottom && disabledCheckboxConfirm) {
            setDisabledCheckboxConfirm(false);
        }
    };

    useEffect(handleDisabledCheckboxConfirm, [idComunicado]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                px: 2,
                gap: { xs: 0.5, md: 2 },
                height: { xs: '40vh', md: 400 },
            }}
        >
            {!isDownMd && <ComunicadoContentImage comunicado={comunicado} />}

            {comunicado?.dsConteudo && (
                <Box
                    ref={conteudoRef}
                    onScroll={onScrollContent}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 2,
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        py: 1,
                        gap: 1,
                    }}
                >
                    {isDownMd && <ComunicadoContentImage comunicado={comunicado} />}

                    <RichContent dangerouslySetInnerHTML={{ __html: comunicado.dsConteudo }} />
                </Box>
            )}
        </Box>
    );
}
